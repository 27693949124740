import React from "react";
import style from "../../styles/header.module.css";

export default function Header() {
  return (
    <div className={style.videoContainer}>
      <video autoPlay muted loop className={style.bgVideo}>
        <source
          src={
            "https://eleditorplatense.s3.amazonaws.com/Delsud-website/video-nosotros.webm"
          }
          type="video/mp4"
        />
      </video>
    </div>
  );
}
