import React, { useState } from "react";
import style from "./styles/empresaAccordion.module.css";

export default function EmpresaAccordion({
  smallSize,
  mediumSize,
  isMobile,
  logo,
  widthMobile,
  width,
  company,
  description,
  link,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={style.accordion}
      key={logo}
      style={{
        width: smallSize ? 330 : mediumSize ? 360 : "auto",
        margin: isMobile && 10,
      }}
      onClick={toggleAccordion}
      id="hovereable"
    >
      <div className={style.accordionPortada} id="hovereable">
        <div className={style.accordionLogo} id="hovereable">
          <img
            src={logo}
            alt={`logo-${company}`}
            style={{ width: isMobile ? widthMobile : width }}
            id="hovereable"
          />
        </div>
      </div>
      <div
        className={`${style.accordionDetail} ${isOpen && style.open}`}
        id="hovereable"
      >
        <p>{description}</p>
        {link && (
          <div className={style.accordionButton} id="hovereable">
            <a href={link} target="_blank" id="hovereable">
              Visitá la web
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
