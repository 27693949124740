import React from "react";
import EmpresaAccordion from "../../../../Components/Accordion/EmpresaAccordion/EmpresaAccordion";
import style from "../../styles/accordion.module.css";

import { useMediaQuery } from "react-responsive";
import { useResponsive } from "../../../../Hooks/useResponsive";

import desarrollos from "../../images/logo_desarrollos.png";
import editor from "../../images/logo_editor.png";
import elal from "../../images/logo_elal.png";
import elemental from "../../images/logo_elemental.png";
import flexy from "../../images/logo_flexy.png";
import merci from "../../images/logo_merci.svg";
import sacra from "../../images/logo_sacra.png";
import trust from "../../images/logo_trust.png";
import wallet from "../../images/logo_wallet.png";

export default function CompanyAccordion() {
  const isMobile = useResponsive();
  const smallSize = useMediaQuery({ query: "(max-width: 768px)" });
  const mediumSize = useMediaQuery({ query: "(max-width: 1279.95px)" });

  const empresas = [
    {
      smallSize: smallSize,
      mediumSize: mediumSize,
      isMobile: isMobile,
      logo: desarrollos,
      widthMobile: 110,
      width: 170,
      company: "DesarrollosDelsud",
      description:
        "Accedé a tu terreno de forma rápida y con la mejor financiación. Somos una de las empresas desarrolladoras urbanísticas más completas y eficientes del mercado.",
      link: "https://www.desarrollosdelsud.com.ar/",
    },
    {
      smallSize: smallSize,
      mediumSize: mediumSize,
      isMobile: isMobile,
      logo: wallet,
      widthMobile: 100,
      width: 180,
      company: "BlackWallet",
      description:
        "Somos una fintech de alcance nacional que nació para brindarte oportunidades. Llegamos para demostrar que lo que creías inalcanzable, ahora es para vos.",
      link: "https://blackwallet.com.ar/",
    },
    {
      smallSize: smallSize,
      mediumSize: mediumSize,
      isMobile: isMobile,
      logo: elemental,
      widthMobile: 160,
      width: 270,
      company: "Elemental",
      description:
        "Nuestra misión es que cada familia pueda cumplir el sueño de tener su casa propia. Conocé los diferentes modelos de casas que tenemos y ¡personalizá la tuya!",
      link: "https://elementalconstructora.com.ar/",
    },
    {
      smallSize: smallSize,
      mediumSize: mediumSize,
      isMobile: isMobile,
      logo: editor,
      widthMobile: 100,
      width: 150,
      company: "elEditor",
      description:
        "Actualidad, política, deportes, policiales. Encontrá todo lo que hay que saber para estar bien informado en El Editor Platense; periodismo del que hacía falta.",
      link: "https://eleditorplatense.com/",
    },
    {
      smallSize: smallSize,
      mediumSize: mediumSize,
      isMobile: isMobile,
      logo: flexy,
      widthMobile: 110,
      width: 180,
      company: "Flexy",
      description:
        "Transformamos el proceso de alquiler y compra de una propiedad en una experiencia online sencilla y accesible para todas las personas. Mudarte y/o gestionar tus propiedades ahora es más fácil con Flexy.",
      link: "https://flexy.com.ar/",
    },
    {
      smallSize: smallSize,
      mediumSize: mediumSize,
      isMobile: isMobile,
      logo: trust,
      widthMobile: 140,
      width: 210,
      company: "TrustFund",
      description:
        "Nuestro innovador sistema hace que obtener una garantía de alquiler se transforme en un procedimiento ágil, sin complicaciones y accesible para todos, otorgando la posibilidad de acceder a una vivienda en menos de 24 horas. Trust Fund es la garantía de confianza para inquilinos, propietarios e inmobiliarias.",
      link: "https://trustfund.com.ar/",
    },
    {
      smallSize: smallSize,
      mediumSize: mediumSize,
      isMobile: isMobile,
      logo: merci,
      widthMobile: 180,
      width: 260,
      company: "Merci",
      description:
        "Atendemos las necesidades de cobertura sobre la vida y patrimonio de nuestros clientes con el mercado asegurador, brindándoles asesoramiento frente a eventuales riesgos y acompañamiento para cubrirlos.",
      link: "https://mercibroker.com.ar/",
    },
    {
      smallSize: smallSize,
      mediumSize: mediumSize,
      isMobile: isMobile,
      logo: sacra,
      widthMobile: 90,
      width: 130,
      company: "Sacra",
      description:
        "Aseguramos el efectivo cumplimiento del derecho a la vida, salud y seguridad de nuestros clientes y sus familias, con un respaldo sólido y de confianza.",
    },
    {
      smallSize: smallSize,
      mediumSize: mediumSize,
      isMobile: isMobile,
      logo: elal,
      widthMobile: 90,
      width: 130,
      company: "ElalVenture",
      description:
        "Somos la primera venture capital de la ciudad de La Plata. Nos enfocamos en proyectos basados en ciencias de la vida, que brinden soluciones transformadoras a problemas de alcance global.",
      link: "https://elalventure.com/",
    },
  ];

  return (
    <div className={style.contenedor}>
      {empresas.map((empresa) => (
        <EmpresaAccordion
          smallSize={empresa.smallSize}
          mediumSize={empresa.mediumSize}
          isMobile={empresa.isMobile}
          logo={empresa.logo}
          widthMobile={empresa.widthMobile}
          width={empresa.width}
          company={empresa.company}
          description={empresa.description}
          link={empresa.link}
        />
      ))}
    </div>
  );
}
