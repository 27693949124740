import React from "react";
import style from "../../styles/historia.module.css";

export default function Historia() {
  return (
    <div className={style.container}>
      <div className={style.header}>
        <h2>Nuestros comienzos</h2>
      </div>
      <div className={style.text}>
        <p>
          En el mes de mayo del 2018 alquilamos nuestra primera oficina,
          amueblada y con pocos metros cuadrados, donde realizamos tres
          operaciones inmobiliarias que nos permitieron cubrir costos y
          reinvertir las ganancias, marcando así, el inicio de <b>Delsud</b>.
        </p>
        <p style={{fontStyle: 'italic'}}>
          “Cada cliente contribuyó con nuestro crecimiento, convirtiéndonos en
          especialistas en el rubro en tiempo récord. Por supuesto, significó
          una enorme apuesta personal y dedicación absoluta, valores que
          sostenemos hasta el día de hoy en cada operación.
        </p>
        <p style={{fontStyle: 'italic'}}>
          Actualmente contamos con más de 100 empleados. Generamos firmas
          dedicadas al desarrollo inmobiliario, la construcción tradicional y en
          seco, el sector financiero, los medios de comunicación y la
          biotecnología. Queremos marcar una diferencia en cada uno de los
          sectores en los que operamos."
        </p>
        <p>
          <b>Eugenia Martinez, Co Fundadora de Delsud.</b>
        </p>
      </div>
    </div>
  );
}
