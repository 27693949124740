import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Pages/Home/Home.jsx";
import Nosotros from "../Pages/Nosotros/Nosotros.jsx";
import Novedades from "../Pages/Novedades/Novedades.jsx";
import Empresas from "../Pages/Empresas/Empresas.jsx";
import RRHH from "../Pages/RRHH/RRHH";
import Contacto from "../Pages/Contacto/Contacto.jsx";
import ScrollToTop from "./ScrollToTop";
import ContactoExito from "../Pages/ContactoExito/ContactoExito.jsx";
import PostulacionExito from "../Pages/PostulacionExito/PostulacionExito.jsx";

function Rutas() {
  return (
    <>
      <div>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="nosotros" element={<Nosotros />} />
            <Route path="novedades" element={<Novedades />} />
            <Route path="empresas" element={<Empresas />} />
            <Route path="rrhh" element={<RRHH />} />
            <Route
              path="contacto_exitoso_rrhh"
              element={<PostulacionExito />}
            />
            <Route path="contacto" element={<Contacto />} />
            <Route path="contacto_exitoso" element={<ContactoExito />} />
          </Routes>
        </ScrollToTop>
      </div>
    </>
  );
}

export default Rutas;
