import React from "react";
import style from "../../styles/header.module.css";

import arrow from "../../../../assets/rightArrow.svg";

export default function Header() {
  return (
    <div className={style.container}>
      <div style={{
        display: 'flex', gap: 10
      }}>
        <img src={arrow} alt="flecha" />
        <h1>Nuestras empresas</h1>
      </div>
    </div>
  );
}
