import React from "react";
import style from "../../styles/flipBoxes.module.css";

import integridad from "../../images/integridad.svg";
import responsabilidad from "../../images/responsabilidad.svg";
import compromiso from "../../images/compromiso.svg";
import innovacion from "../../images/innovacion.svg";

const box = (image, title, description) => {
  return (
    <div className={style.flipBox}>
      <div className={style.flipBoxInner}>
        <div className={style.flipBoxFront}>
          <img src={image} alt={title} />
          <h2 className={style.boxTitle}>{title}</h2>
        </div>
        <div className={style.flipBoxBack}>
          <p className={style.text}>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default function FlipBoxes() {
  const elements = [
    {
      image: integridad,
      title: "Integridad",
      description:
        "Nuestro equipo de trabajo está formado por profesionales altamente calificados que confían, apuestan y se identifican con los proyectos que generamos.",
    },
    {
      image: responsabilidad,
      title: "Responsabilidad",
      description:
        "Somos conscientes del déficit que tiene nuestro país en distintos sectores. Por eso, como empresa, creemos que todos merecen tener la oportunidad de alcanzar una mejor calidad de vida.",
    },
    {
      image: compromiso,
      title: "Compromiso",
      description:
        "El valor que nos define es el compromiso. No solo en cada una de las labores que desempeñamos, sino también predicamos a todos aquellos que elijan involucrarse en nuestros proyectos.",
    },
    {
      image: innovacion,
      title: "Innovación",
      description:
        "Creemos que la innovación genera oportunidades. Por eso, estamos en constante movimiento, apostando al desarrollo y evolución de cada proyecto.",
    },
  ];

  return (
    <div>
      <div className={style.container}>
        {elements.map((element) =>
          box(element.image, element.title, element.description)
        )}
      </div>
    </div>
  );
}
