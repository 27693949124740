import React from "react";
import style from "../../styles/contador.module.css";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

export default function Contador() {
  return (
    <div className={style.container}>
      <div className={style.content} />
      <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
        {({ isVisible }) => (
          <div className={style.contadores}>
            <div className={style.contador}>
              {isVisible ? <CountUp end={5} /> : null}
              <p className={style.contadorText}>
                años de <br /> antigüedad
              </p>
            </div>
            <div className={style.contador}>
              <span>+</span>
              {isVisible ? <CountUp end={100} /> : null}
              <p className={style.contadorText}>
                empleados <br /> contratados
              </p>
            </div>
            <div className={style.contador}>
              <span>+</span>
              {isVisible ? <CountUp end={1200} /> : null}
              <p className={style.contadorText}>clientes</p>
            </div>
            <div className={style.contador}>
              <span>+</span>
              {isVisible ? <CountUp end={10} /> : null}
              <p className={style.contadorText}>
                unidades <br /> de negocio
              </p>
            </div>
          </div>
        )}
      </VisibilitySensor>
    </div>
  );
}
