import React from "react";
import { useMetaTags } from "../../Hooks/useMetaTags";
import Header from "./sections/Header/Header";
import NovedadesAccordion from "./sections/Accordion/Accordion";

export default function Novedades() {
  useMetaTags({
    title: "Novedades",
    description: "Enterate lo último de nuestras unidades de negocio.",
  });

  return (
    <div>
      <Header />
      <NovedadesAccordion />
    </div>
  );
}
