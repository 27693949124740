import React, { useState } from "react";
import style from "../../styles/accordion.module.css";
import NovedadAccordion from "../../../../Components/Accordion/NovedadAccordion/NovedadAccordion";
import usePagination from "../../../../Hooks/usePagination";
import novedades from "../../novedades.json";

import backArrow from "../../../../assets/backArrow.svg";
import nextArrow from "../../../../assets/nextArrow.svg";

export default function NovedadesAccordion() {
  const itemsPerPage = 6;
  const { next, prev, jump, currentData, currentPage, maxPage } = usePagination(
    novedades,
    itemsPerPage
  );

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= maxPage; i++) {
      pageNumbers.push(
        <button
          id="hovereable"
          key={i}
          onClick={() => jump(i)}
          className={`${style.buttonNumber} ${
            currentPage === i ? style.active : ""
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className={style.contenedorGeneral}>
      <div className={style.contenedor}>
        {currentData().map((novedad) => (
          <NovedadAccordion
            logo={novedad.logo}
            empresa={novedad.empresa}
            image={novedad.image}
            title={novedad.title}
            text={novedad.text}
            key={novedad.title}
          />
        ))}
      </div>
      <div className={style.paginacion}>
        <button
          id="hovereable"
          onClick={prev}
          disabled={currentPage === 1}
          className={`${style.textButton} ${
            currentPage === 1 ? style.disabled : style.active
          }`}
        >
          <img id="hovereable" src={backArrow} alt="Anterior" />
        </button>
        {renderPageNumbers()}
        <button
          id="hovereable"
          onClick={next}
          className={`${style.textButton} ${
            currentPage === maxPage ? style.disabled : style.active
          }`}
        >
          <img id="hovereable" src={nextArrow} alt="Siguiente" />
        </button>
      </div>
    </div>
  );
}
