export const useMetaTags = (metaParams) => {
  document.title = metaParams.title;
  const ogTitleTag = document.querySelector('meta[property="og:title"]');
  if (ogTitleTag) {
    ogTitleTag.setAttribute("content", metaParams.title);
  }
  const ogDescriptionTag = document.querySelector(
    'meta[property="og:description"]'
  );
  if (ogDescriptionTag) {
    ogDescriptionTag.setAttribute("content", metaParams.description);
  }
};
