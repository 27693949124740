import React from "react";
import style from "../../styles/blueContainer.module.css";

import locationIcon from "../../images/locationIcon.svg";
import mailIcon from "../../images/mailIcon.svg";
import wspIcon from "../../images/wspIcon.svg";

const contactElement = (icon, type, text) => {
  return (
    <div className={style.elementContainer} key={type}>
      <span className={style.elementIcon}>
        <img src={icon} alt={`icono-${type}`} />
      </span>
      <div className={style.elementText}>
        {text.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    </div>
  );
};

export default function BlueContainer() {
  const contacts = [
    { icon: mailIcon, type: "mail", text: ["info@grupodelsud.com"] },
    { icon: wspIcon, type: "whatsapp", text: ["+54 9 221 5023328"] },
    {
      icon: locationIcon,
      type: "ubicacion",
      text: ["37 n°125, e/ 117 y 118.", "La Plata - Bs. As."],
    },
  ];

  return (
    <div className={style.blueContainer}>
      <div className={style.blueInfo}>
        {contacts.map((contact) =>
          contactElement(contact.icon, contact.type, contact.text)
        )}
      </div>
    </div>
  );
}
