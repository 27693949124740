import React, { useEffect, useState } from "react";
import style from "../../styles/form.module.css";
import axios from "axios";
import { BASE_URL } from "../../../../variables";
import { useForm } from "../../../../Hooks/useForm";
import Loader from "../../../../Components/Loader/Loader";

export default function Form() {
  const initialForm = {
    name: "",
    email: "",
    message: "",
  };

  const [isLoading, setIsLoading] = useState(false);

  const validateForm = (form) => {
    let errors = {};
    let regexEmail =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (form.name === "") {
      errors.name = "El campo está vacío";
    }

    if (form.email === "") {
      errors.email = "El campo está vacío";
    } else if (!regexEmail.test(form.email)) {
      errors.email = "El correo electrónico no es correcto";
    }

    if (form.message === "") {
      errors.message = "El campo está vacío";
    }

    return errors;
  };

  const { form, errors, response, send, handleChange, handleSubmit } = useForm(
    initialForm,
    validateForm
  );

  useEffect(() => {
    if (response) {
      setIsLoading(true);
      axios
        .post(`${BASE_URL}/api/contacto`, form)
        .then(function (res) {
          window.location.href = "/contacto_exitoso";
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [response]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={style.container}>
      <form className={style.form} onSubmit={handleSubmit}>
        <div className={style.fields}>
          <div className={style.inputsContainer}>
            <section className={style.inputContainer}>
              <label>Nombre y Apellido:</label>
              <input
                type="text"
                name="name"
                value={form.name}
                className={style.input}
                onChange={handleChange}
              />
              {send && errors.name && (
                <p className={style.error}>{errors.name}</p>
              )}
            </section>
            <section className={style.inputContainer}>
              <label>Mail:</label>
              <input
                type="text"
                name="email"
                value={form.email}
                className={style.input}
                onChange={handleChange}
              />
              {send && errors.email && (
                <p className={style.error}>{errors.email}</p>
              )}
            </section>
          </div>
          <section className={style.textareaContainer}>
            <label>Mensaje:</label>
            <textarea
              name="message"
              value={form.message}
              className={style.textarea}
              onChange={handleChange}
            />
            {send && errors.message && (
              <p className={style.error}>{errors.message}</p>
            )}
          </section>
        </div>
        <div className={style.button} id="hovereable">
          <button id="hovereable">Enviar</button>
        </div>
      </form>
    </div>
  );
}
