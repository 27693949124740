import React, { useState } from "react";
import Loader from "../../../../Components/Loader/Loader";
import { useForm } from "react-hook-form";
import axios from "axios";
import { BASE_URL } from "../../../../variables";
import "./formulario.css";

export default function FormularioPeriodismo() {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("area", data.area);
    formData.append("nombreCompleto", data.nombreCompleto);
    formData.append("mail", data.mail);
    formData.append("mensaje", data.mensaje);
    formData.append("telefono", data.telefono);
    formData.append("preguntaExtra1", data.preguntaExtra1);
    formData.append("preguntaExtra2", data.preguntaExtra2);
    formData.append("file", data.archivo[0]);

    setIsLoading(true);

    axios
      .post(`${BASE_URL}/api/rrhh`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (res) {
        window.location.href = "/contacto_exitoso_rrhh";
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="formulario-container">
      <div className="formulario-datos">
        <p className="formulario-saludo">¡Hola!</p>
        <p className="formulario-sub">Estamos ansiosos por conocerte</p>
        <p className="formulario-area">
          Te estás postulando para el área de <b>Periodismo</b>
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register("area", { value: "Periodismo" })} />
          <div className="fila-formulario">
            <div className="campo-formulario">
              <label htmlFor="nombreCompleto">
                ¿Cómo es tu nombre y apellido?
              </label>
              <input
                {...register("nombreCompleto", {
                  required: "Campo obligatorio",
                  maxLength: {
                    value: 40,
                    message: "Máximo 40 caracteres",
                  },
                })}
              />
              {errors.nombreCompleto && <p>{errors.nombreCompleto.message}</p>}
            </div>
            <div className="campo-formulario">
              <label htmlFor="mail">Compartinos un mail que uses</label>
              <input
                type="text"
                {...register("mail", {
                  required: "Campo obligatorio",
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: "Dirección de mail invalida",
                  },
                })}
              />
              {errors.mail && <p>{errors.mail.message}</p>}
            </div>
          </div>
          <div className="fila-formulario">
            <div className="campo-formulario">
              <label htmlFor="telefono">
                Un teléfono al que nos podamos comunicar
              </label>
              <input
                type="number"
                {...register("telefono", {
                  required: "Campo obligatorio",
                  maxLength: {
                    value: 20,
                    message: "Máximo 20 caracteres",
                  },
                })}
              />
              {errors.telefono && <p>{errors.telefono.message}</p>}
            </div>
            <div className="campo-formulario archivo">
              <label htmlFor="archivo">Adjuntá un CV o portfolio</label>
              <input
                type="file"
                {...register("archivo", {
                  required: "Campo obligatorio",
                })}
                id="hovereable"
              />
              {errors.mail && <p>{errors.mail.message}</p>}
            </div>
          </div>
          <div className="fila-formulario">
            <div className="campo-formulario">
              <label htmlFor="preguntaExtra1">
                ¿Contás con movilidad propia?
              </label>
              <input
                {...register("preguntaExtra1", {
                  required: "Campo obligatorio",
                  maxLength: {
                    value: 40,
                    message: "Máximo 40 caracteres",
                  },
                })}
              />
              {errors.preguntaExtra1 && <p>{errors.preguntaExtra1.message}</p>}
            </div>
            <div className="campo-formulario">
              <label htmlFor="preguntaExtra2">
                Si tuvieras que elegir una categoría, ¿cuál sería?
              </label>
              <input
                {...register("preguntaExtra2", {
                  required: "Campo obligatorio",
                  maxLength: {
                    value: 40,
                    message: "Máximo 40 caracteres",
                  },
                })}
              />
              {errors.preguntaExtra2 && <p>{errors.preguntaExtra2.message}</p>}
            </div>
          </div>
          <div className="fila-formulario">
            <div className="campo-formulario campo-formulario-textarea">
              <label htmlFor="mensaje">¿Por qué te interesa este puesto?</label>
              <textarea
                {...register("mensaje", {
                  required: "Campo obligatorio",
                  maxLength: {
                    value: 600,
                    message: "Máximo 600 caracteres",
                  },
                  minLength: {
                    value: 50,
                    message: "Mínimo 50 caracteres",
                  },
                })}
              />
              {errors.mensaje && <p>{errors.mensaje.message}</p>}
            </div>
          </div>
          <input
            className="boton-enviar-formulario-contacto"
            type="submit"
            value="Enviar"
            id="hovereable"
          />
        </form>
      </div>
    </div>
  );
}
