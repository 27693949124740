import React from "react";
import style from "../../styles/top.module.css";
import { useResponsive } from "../../../../Hooks/useResponsive";

import clockIcon from "../../images/clock.svg";
import mailIcon from "../../images/mail.svg";

export default function Top() {
  const isMobile = useResponsive();

  return (
    <section className={style.container}>
      <div className={style.centerElements}>
        <div className={style.box1}>
          <span className={style.icons}>
            <img src={mailIcon} alt="icono-mail" />
          </span>
          <a>info@grupodelsud.com</a>
        </div>
        <div className={style.box2}>
          <span className={style.icons}>
            <img src={clockIcon} alt="icono-reloj" />
          </span>
          <span>
            {!isMobile && "horario de atencion:"} Lun-Vier, 9:00 - 17:00hs
          </span>
        </div>
      </div>
    </section>
  );
}
