import React from "react";
import { useMetaTags } from "../../Hooks/useMetaTags";
import style from "./styles/empresas.module.css";
import Header from "./sections/Header/Header";
import CompanyAccordion from "./sections/Accordion/Accordion";
import Slider from "./sections/Slider/Slider";

export default function Empresas() {
  useMetaTags({
    title: "Empresas",
    description: "Estas son las unidades que componen Delsud.",
  });

  return (
    <div className={style.container}>
      <Header />
      <CompanyAccordion />
      <Slider />
    </div>
  );
}
