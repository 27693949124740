import React from "react";
import style from "./styles/footer.module.css";

import BlueContainer from "./sections/BlueContainer";
import GreyContainer from "./sections/GreyContainer";
import Bottom from "./sections/Bottom";

export default function Footer() {
  return (
    <>
      <div className={style.footer}>
        <BlueContainer />
        <GreyContainer />
      </div>
      <Bottom />
    </>
  );
}
