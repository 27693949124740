import React from "react";
import { useMetaTags } from "../../Hooks/useMetaTags";
import HeroRrhh from "./Section/HeroRrhh";
import BloqueTextoFormulario from "./Section/BloqueTextoFormulario";
import Formulario from "./Section/Formulario";

const RRHH = () => {
  useMetaTags({
    title: "RRHH",
    description: "Trabajá con nosotros. Tenemos una oportunidad para vos.",
  });

  return (
    <>
      <HeroRrhh />
      <BloqueTextoFormulario />
      <Formulario />
    </>
  );
};

export default RRHH;
