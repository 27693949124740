import React, { useState } from "react";
import FormularioVentas from "./formularios/FormularioVentas";
import FormularioAdministracion from "./formularios/FormularioAdministracion";
import FormularioArquitectura from "./formularios/FormularioArquitectura";
import FormularioPeriodismo from "./formularios/FormularioPeriodismo";
import FormularioFinanzas from "./formularios/FormularioFinanzas";
import FormularioMarketing from "./formularios/FormularioMarketing";
import { Link } from "react-scroll";
import "../Styles/botonesFormulario.css";
//React Icon
import { AiFillPlusCircle } from "react-icons/ai";

export default function Formulario() {
  const [formularioVentas, setFormularioVentas] = useState();
  const [formularioAdministracion, setFormularioAdministracion] = useState();
  const [formularioArquitectura, setFormularioArquitectura] = useState();
  const [formularioPeriodismo, setFormularioPeriodismo] = useState();
  const [formularioFinanzas, setFormularioFinanzas] = useState();
  const [formularioMarketing, setFormularioMarketing] = useState();
  function MostrarFormularioVentas() {
    setFormularioVentas(true);
    setFormularioAdministracion(false);
    setFormularioArquitectura(false);
    setFormularioPeriodismo(false);
    setFormularioFinanzas(false);
    setFormularioMarketing(false);
  }

  function MostrarFormularioAdministracion() {
    setFormularioAdministracion(true);
    setFormularioVentas(false);
    setFormularioArquitectura(false);
    setFormularioPeriodismo(false);
    setFormularioFinanzas(false);
    setFormularioMarketing(false);
  }

  function MostrarFormularioArquitectura() {
    setFormularioArquitectura(true);
    setFormularioVentas(false);
    setFormularioAdministracion(false);
    setFormularioPeriodismo(false);
    setFormularioFinanzas(false);
    setFormularioMarketing(false);
  }

  function MostrarFormularioPeriodismo() {
    setFormularioPeriodismo(true);
    setFormularioArquitectura(false);
    setFormularioVentas(false);
    setFormularioAdministracion(false);
    setFormularioFinanzas(false);
    setFormularioMarketing(false);
  }

  function MostrarFormularioFinanzas() {
    setFormularioPeriodismo(false);
    setFormularioArquitectura(false);
    setFormularioVentas(false);
    setFormularioAdministracion(false);
    setFormularioFinanzas(true);
    setFormularioMarketing(false);
  }

  function MostrarFormularioMarketing() {
    setFormularioPeriodismo(false);
    setFormularioArquitectura(false);
    setFormularioVentas(false);
    setFormularioAdministracion(false);
    setFormularioFinanzas(false);
    setFormularioMarketing(true);
  }

  return (
    <div className="formulario-botones-container">
      <div className="formulario-botones-fila">
        <Link
          className="formulario-boton boton-ventas"
          to="form"
          spy={true}
          smooth={true}
          onClick={MostrarFormularioVentas}
          id="hovereable"
        >
          <div className="boton-text-container" id="hovereable">
            <p id="hovereable">Ventas</p>
            <AiFillPlusCircle id="hovereable" />
          </div>
        </Link>
        <Link
          className="formulario-boton boton-administracion"
          to="form"
          spy={true}
          smooth={true}
          onClick={MostrarFormularioAdministracion}
          id="hovereable"
        >
          <div className="boton-text-container" id="hovereable">
            <p id="hovereable">Administración</p>
            <AiFillPlusCircle id="hovereable" />
          </div>
        </Link>
      </div>
      <div className="formulario-botones-fila">
        <Link
          className="formulario-boton boton-arquitectura"
          to="form"
          spy={true}
          smooth={true}
          onClick={MostrarFormularioArquitectura}
          id="hovereable"
        >
          <div className="boton-text-container" id="hovereable">
            <p id="hovereable">Arquitectura</p>
            <AiFillPlusCircle id="hovereable" />
          </div>
        </Link>
        <Link
          className="formulario-boton boton-periodismo"
          to="form"
          spy={true}
          smooth={true}
          onClick={MostrarFormularioPeriodismo}
          id="hovereable"
        >
          <div className="boton-text-container" id="hovereable">
            <p id="hovereable">Periodismo</p>
            <AiFillPlusCircle id="hovereable" />
          </div>
        </Link>
      </div>
      <div className="formulario-botones-fila">
        <Link
          className="formulario-boton boton-finanzas"
          to="form"
          spy={true}
          smooth={true}
          onClick={MostrarFormularioFinanzas}
          id="hovereable"
        >
          <div className="boton-text-container" id="hovereable">
            <p id="hovereable">Finanzas</p>
            <AiFillPlusCircle id="hovereable" />
          </div>
        </Link>
        <Link
          className="formulario-boton boton-marketing"
          to="form"
          spy={true}
          smooth={true}
          onClick={MostrarFormularioMarketing}
          id="hovereable"
        >
          <div className="boton-text-container" id="hovereable">
            <p id="hovereable">Marketing & Diseño</p>
            <AiFillPlusCircle id="hovereable" />
          </div>
        </Link>
      </div>
      <div className="formulario" id="form">
        {formularioVentas && <FormularioVentas />}
        {formularioAdministracion && <FormularioAdministracion />}
        {formularioArquitectura && <FormularioArquitectura />}
        {formularioPeriodismo && <FormularioPeriodismo />}
        {formularioFinanzas && <FormularioFinanzas />}
        {formularioMarketing && <FormularioMarketing />}
      </div>
    </div>
  );
}
