import React from "react";
import style from "../../styles/footer.module.css";

export default function Bottom() {
  return (
    <div className={style.bottomFooter}>
      <p>
        Copyright 2024. Delsud
        <br />
        Términos y condiciones
      </p>
    </div>
  );
}
