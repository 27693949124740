import React, { useState } from "react";
import style from "./styles/novedadAccordion.module.css";

export default function NovedadAccordion({
  logo,
  empresa,
  image,
  title,
  text,
}) {
  /*const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };*/

  return (
    <div key={title} className={style.accordion}>
      <div className={style.accordionPortada}>
        <div className={style.imageCover}>
          <img
            src={logo}
            alt={`Logo ${empresa}`}
            className={style.rotuloEmpresa}
          />
          <img src={image} alt={title} className={style.mainImage} />
        </div>
      </div>
      <div className={`${style.accordionDetail}`}>
        <h4>{title}</h4>
        <p>{text}</p>
      </div>
    </div>
  );
}
