import React from "react";
import style from "../../styles/textBox.module.css";

export default function TextBox() {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", marginBottom: 150 }}
    >
      <div className={style.container}>
        <div className={style.grayContainer}>
          <p>
            "El camino al éxito es pensar día a día qué vas a mejorar mañana,
            sin repetir lo hecho hoy"
          </p>
        </div>
        <div className={style.whiteContainer}>
          <h4>Francisco Vila Basualdo</h4>
          <p>Co Fundador & CEO de Delsud</p>
        </div>
      </div>
    </div>
  );
}
