import React from "react";

import logo from "../../../../assets/logoDelsud.svg";

export default function LogoWidget() {
  return (
    <div style={{ width: "100%" }} >
      <img style={{ width: "27%", zIndex: 100 }} src={logo} alt="logo-Delsud" id="hovereable"/>
    </div>
  );
}
