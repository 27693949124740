import React from "react";
import style from "../../styles/sumate.module.css";
import { Link } from "react-router-dom";
import flecha from "../../images/flecha.svg"

export default function Sumate() {
  return (
    <div className={style.container}>
      <div className={style.title}>
        <h2>Trabajá con nosotros</h2>
      </div>
      <div className={style.text}>
        <p>
          <b>Somos un equipo de trabajo joven y proactivo</b> que busca hacer
          las cosas de manera distinta. Para eso, damos la oportunidad y el
          respaldo a quienes proponen buenas ideas y saben cómo ejecutarlas.
        </p>
      </div>
      <div className={style.buttonContainer}>
        <Link to={"/RRHH"} id="hovereable" style={{ textDecoration: "none" }}>
          <div id="hovereable" className={style.buttonContent}>
            <p id="hovereable">Formá parte de Delsud</p>
            <img src={flecha} alt="flecha" id="hovereable"/>
          </div>
        </Link>
      </div>
    </div>
  );
}
