import React from "react";
import style from "../../styles/header.module.css";

import arrow from "../../../../assets/rightArrow.svg";

export default function Header() {
  return (
    <div className={style.container}>
      <img src={arrow} />
      <h1>Dejanos tu mensaje</h1>
    </div>
  );
}
