import React from "react";
import { useResponsive } from "../../../../Hooks/useResponsive";
import style from "../../styles/lastNews.module.css";
import "../../styles/slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper';
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/autoplay';
import novedades from "../../../Novedades/novedades.json";

const Item = ({ image, title, text, logo, empresa }) => {
  return (
    <div className={style.item}>
      <div className={style.portada}>
        <img src={logo} alt={`Logo ${empresa}`} className={style.rotulo} />
        <img src={image} alt={title} className={style.mainImage} />
      </div>
      <div className={style.text}>
        <h4>{title}</h4>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default function LastNews() {
  const isMobile = useResponsive();
  let lastNews = novedades.slice(0, 6);

  return (
    <div className={style.containerGeneral}>
      <div className={style.encabezado}>
        <h4 style={{ fontWeight: 500 }}>Lo último</h4>
      </div>
      <div className={style.container}>
        {isMobile ? (
          <Swiper
            className={style.swiper}
            loop={true}
            modules={[Autoplay]}
            autoplay={{ delay: 3000 }}
          >
            <SwiperSlide className={style.swiperSlide}>
              <Item
                image={lastNews[0].image}
                title={lastNews[0].title}
                text={lastNews[0].text}
                logo={lastNews[0].logo}
                empresa={lastNews[0].empresa}
              />
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <Item
                image={lastNews[1].image}
                title={lastNews[1].title}
                text={lastNews[1].text}
                logo={lastNews[1].logo}
                empresa={lastNews[1].empresa}
              />
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <Item
                image={lastNews[2].image}
                title={lastNews[2].title}
                text={lastNews[2].text}
                logo={lastNews[2].logo}
                empresa={lastNews[2].empresa}
              />
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <Item
                image={lastNews[3].image}
                title={lastNews[3].title}
                text={lastNews[3].text}
                logo={lastNews[3].logo}
                empresa={lastNews[3].empresa}
              />
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <Item
                image={lastNews[4].image}
                title={lastNews[4].title}
                text={lastNews[4].text}
                logo={lastNews[4].logo}
                empresa={lastNews[4].empresa}
              />
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <Item
                image={lastNews[5].image}
                title={lastNews[5].title}
                text={lastNews[5].text}
                logo={lastNews[5].logo}
                empresa={lastNews[5].empresa}
              />
            </SwiperSlide>
          </Swiper>
        ) : (
          <Swiper
            className={style.swiper}
            loop={true}
            modules={[Autoplay]}
            autoplay={{ delay: 3000 }}
          >
            <SwiperSlide className={style.swiperSlide}>
              <Item
                image={lastNews[0].expandedImage}
                title={lastNews[0].title}
                text={lastNews[0].text}
                logo={lastNews[0].logo}
                empresa={lastNews[0].empresa}
              />
              <Item
                image={lastNews[1].expandedImage}
                title={lastNews[1].title}
                text={lastNews[1].text}
                logo={lastNews[1].logo}
                empresa={lastNews[1].empresa}
              />
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <Item
                image={lastNews[2].expandedImage}
                title={lastNews[2].title}
                text={lastNews[2].text}
                logo={lastNews[2].logo}
                empresa={lastNews[2].empresa}
              />
              <Item
                image={lastNews[3].expandedImage}
                title={lastNews[3].title}
                text={lastNews[3].text}
                logo={lastNews[3].logo}
                empresa={lastNews[3].empresa}
              />
            </SwiperSlide>
            <SwiperSlide className={style.swiperSlide}>
              <Item
                image={lastNews[4].expandedImage}
                title={lastNews[4].title}
                text={lastNews[4].text}
                logo={lastNews[4].logo}
                empresa={lastNews[4].empresa}
              />
              <Item
                image={lastNews[5].expandedImage}
                title={lastNews[5].title}
                text={lastNews[5].text}
                logo={lastNews[5].logo}
                empresa={lastNews[5].empresa}
              />
            </SwiperSlide>
          </Swiper>
        )}
      </div>
    </div>
  );
}
