import React from "react";
import style from "../../styles/filosofia.module.css";
import { useResponsive } from "../../../../Hooks/useResponsive";

import itemIcono from "../../images/itemIcon.png";

const item = (content) => {
  return (
    <div className={style.itemContainer}>
      <img src={itemIcono} alt="item" />
      <p>{content}</p>
    </div>
  );
};

export default function Filosofia() {
  const visionElements = [
    { text: "Compromiso y transparencia" },
    { text: "Solidaridad" },
  ];

  const valoresElements = [
    { text: "Responsabilidad y constancia" },
    { text: "Profesionalismo y vocación" },
    { text: "Competitividad e innovación laboral" },
  ];

  const isMobile = useResponsive();

  return (
    <div className={style.container}>
      <div className={style.titleBigContainer}>
        <div className={style.titleContainer}>
          <h1>Filosofía de Gestión Empresarial</h1>
          <p>Todo lo que tenés que saber sobre la empresa.</p>
        </div>
      </div>
      <div className={style.descriptionContainer}>
        <div
          className={style.descriptionElement}
          style={{ marginLeft: isMobile ? null : 400 }}
        >
          <div className={style.descriptionTitle}>
            <h2>Misión</h2>
            <hr />
          </div>
          <p>
            Consolidarse como un grupo empresarial de referencia en todo el
            país, a través de unidades de negocios que sean vanguardia en cada
            uno de sus rubros
          </p>
        </div>
        <div className={style.descriptionElement}>
          <div className={style.descriptionTitle}>
            <h2>Visión</h2>
            <hr />
          </div>
          <div
            style={{
              display: "flex",
              width: isMobile ? "340px" : "900px",
              alignContent: "flex-end",
              gap: isMobile ? 0 : 60,
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <p style={{ width: 340 }}>
              Buscamos satisfacer las necesidades y superar las expectativas de
              quienes nos eligen, siendo además una empresa que apuesta a la
              formación, desarrollo y crecimiento de todos los que son parte de
              nuestros proyectos. Detrás de nuestro trabajo existen sueños,
              esperanzas y anhelos; pero sobre todo, vocación para alcanzar los
              objetivos
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              {visionElements.map((element) => item(element.text))}
            </div>
          </div>
        </div>
        <div className={style.descriptionElement}>
          <div className={style.descriptionTitle}>
            <h2>Valores</h2>
            <hr />
          </div>
          <div>{valoresElements.map((element) => item(element.text))}</div>
        </div>
      </div>
    </div>
  );
}
