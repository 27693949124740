import React from "react";
import { useResponsive } from "../../Hooks/useResponsive";
import { useMetaTags } from "../../Hooks/useMetaTags";
import Header from "./sections/Header/Header";
import FlipBoxes from "./sections/FlipBoxes/FlipBoxes";
import LastNews from "./sections/LastNews/LastNews";
import TextBox from "./sections/TextBox/TextBox";
import Companies from "./sections/Companies/Companies";

export default function Home() {
  const isMobile = useResponsive();

  useMetaTags({
    title: "Delsud",
    description:
      "Somos un conjunto de empresas nacido en la ciudad de La Plata. Operamos en los rubros inmobiliario, seguros, medios de comunicación, garantías de alquiler, inversiones y finanzas",
  });

  return (
    <div
      style={{
        backgroundImage:
          "url(https://grupodelsud.com/wp-content/uploads/2021/04/Grupo-385.png)",
      }}
    >
      <Header />
      <FlipBoxes />
      <LastNews />
      <TextBox />
      <Companies />
    </div>
  );
}
