import "./App.css";
import NavBar from "./Components/NavBar/NavBar.jsx";
import Rutas from "./Components/Rutas";
import Footer from "./Components/Footer/Footer.jsx";

function App() {
  return (
    <>
      <NavBar />
      <Rutas />
      <Footer />
    </>
  );
}

export default App;
