import React from "react";
import { useMetaTags } from "../../Hooks/useMetaTags";
import Header from "./sections/Header/Header";
import Filosofia from "./sections/Filosofia/Filosofia";
import Historia from "./sections/Historia/Historia";
import Contador from "./sections/Contador/Contador";
import Sumate from "./sections/Sumate/Sumate";

export default function Nosotros() {
  useMetaTags({
    title: "Nosotros",
    description: "Somos un equipo de trabajo joven y proactivo que busca hacer las cosas de manera distinta.",
  });

  return (
    <div
      style={{
        backgroundImage:
          "url(https://grupodelsud.com/wp-content/uploads/2021/04/Grupo-385.png)",
      }}
    >
      <Header />
      <Filosofia />
      <Historia />
      <Contador />
      <Sumate />
    </div>
  );
}
