import React from "react";
import style from "./styles/navBar.module.css";
import { NavLink } from "react-router-dom";
import { useResponsive } from "../../Hooks/useResponsive";

import Top from "./sections/Top/Top.jsx";
import DrawerComponent from "./sections/Drawer/Drawer.jsx";
import LogoWidget from "./sections/LogoWidget/LogoWidget.jsx";

const item = (name, link) => {
  return (
    <NavLink
      id="hovereable"
      className={({ isActive }) => (isActive ? style.active : style.item)}
      to={link}
      key={name}
    >
      {name}
    </NavLink>
  );
};

export default function NavBar() {
  const isMobile = useResponsive();

  const pages = [
    { name: "Nosotros", link: "/nosotros" },
    { name: "Novedades", link: "/novedades" },
    { name: "Empresas", link: "/empresas" },
    { name: "RRHH", link: "/rrhh" },
    { name: "Contacto", link: "/contacto" },
  ];

  return (
    <div className={style.container}>
      <Top />
      {isMobile ? (
        <DrawerComponent />
      ) : (
        <section className={style.navCont}>
          <div className={style.nav}>
            <NavLink to="/">{LogoWidget}</NavLink>
            <div className={style.navLinks}>
              {pages.map((page) => item(page.name, page.link))}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
