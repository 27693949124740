import React from "react";
import { useMetaTags } from "../../Hooks/useMetaTags";
import Header from "./sections/Header/Header";
import Form from "./sections/Form/Form";

export default function Contacto() {
  useMetaTags({
    title: "Contacto",
    description:
      "Comunicate con nosotros para más información.",
  });

  return (
    <div>
      <Header />
      <Form />
    </div>
  );
}
