import React from "react";
import style from "../../styles/greyContainer.module.css";

import facebookIcon from "../../images/facebook.svg";
import instagramIcon from "../../images/instagram.svg";
import linkedinIcon from "../../images/linkedin.svg";
import tiktokIcon from "../../images/tiktok.svg";
import arrow from "../../images/arrow.svg";

const redElement = (name, link, icon) => {
  return (
    <a href={link} target="_blank" id="hovereable" key={name}>
      <img src={icon} alt={`icono-${name}`} id="hovereable" />
    </a>
  );
};

export default function GreyContainer() {
  const socialMedia = [
    {
      name: "facebook",
      link: "https://www.facebook.com/Grupo-Delsud-105202914490053/",
      icon: facebookIcon,
    },
    {
      name: "instagram",
      link: "https://www.instagram.com/delsud.arg/?hl=es",
      icon: instagramIcon,
    },
    {
      name: "linkedin",
      link: "https://www.linkedin.com/company/grupo-delsud/mycompany/",
      icon: linkedinIcon,
    },
    {
      name: "tiktok",
      link: "https://www.tiktok.com/@delsud.arg",
      icon: tiktokIcon,
    },
  ];

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  return (
    <div className={style.greyContainer}>
      <div className={`${style.boxGreyContainer} ${style.redesContainer}`}>
        <p className={style.seguinos}>¡Seguinos en nuestras redes!</p>
        <div className={style.redes}>
          {socialMedia.map((red) => redElement(red.name, red.link, red.icon))}
        </div>
      </div>
      <div className={`${style.boxGreyContainer} ${style.botonSubir}`}>
        <span onClick={scrollToTop} className={style.arrowIcon} id="hovereable">
          <img src={arrow} alt="flecha-subir" id="hovereable"/>
        </span>
        <p>subir</p>
      </div>
    </div>
  );
}
